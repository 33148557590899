<template>
  <v-app id="adminApp">
    <v-navigation-drawer
      width="280"
      :right="lang == 'ar'"
      app
      v-model="drawer"
      class="bg-secondary"
    >
      <h2 class="my-4 clr-primary text-center">{{ $t("V-shape") }}</h2>

      <v-divider></v-divider>

      <v-list nav dense>
        <v-list-item
          v-if="
            user && user.permissions && user.permissions.includes('get_home')
          "
          link
          to="/admin/home"
        >
          <v-list-item-icon>
            <v-icon>mdi-home</v-icon>
          </v-list-item-icon>
          <v-list-item-title>{{ $t("Home") }}</v-list-item-title>
        </v-list-item>

        <v-list-group
          v-if="
            (user &&
              user.permissions &&
              user.permissions.includes('get_division')) ||
            user.permissions.includes('get_group') ||
            user.permissions.includes('get_nutrition_fact') ||
            user.permissions.includes('get_cuisine') ||
            user.permissions.includes('get_tool') ||
            user.permissions.includes('get_unit') ||
            user.permissions.includes('get_country') ||
            user.permissions.includes('get_city') ||
            user.permissions.includes('get_period') ||
            user.permissions.includes('get_bank_name') ||
            user.permissions.includes('get_company') ||
            user.permissions.includes('get_group_name') ||
            user.permissions.includes('get_package')
          "
        >
          <template v-slot:activator>
            <v-list-item-icon>
              <v-icon>mdi-cog</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{ $t("SystemConstant") }}</v-list-item-title>
            </v-list-item-content>
          </template>

          <v-list-item
            link
            to="/admin/division"
            v-if="
              user &&
              user.permissions &&
              user.permissions.includes('get_division')
            "
          >
            <v-list-item-content>
              <v-list-item-title class="mr-menu">{{
                $t("Divisions")
              }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item
            link
            to="/admin/group"
            v-if="
              user && user.permissions && user.permissions.includes('get_group')
            "
          >
            <v-list-item-content>
              <v-list-item-title class="mr-menu">{{
                $t("Groups")
              }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item
            link
            to="/admin/nutrition_fact"
            v-if="
              user &&
              user.permissions &&
              user.permissions.includes('get_nutrition_fact')
            "
          >
            <v-list-item-content>
              <v-list-item-title class="mr-menu">{{
                $t("NutritionFacts")
              }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item
            link
            to="/admin/cuisine"
            v-if="
              user &&
              user.permissions &&
              user.permissions.includes('get_cuisine')
            "
          >
            <v-list-item-content>
              <v-list-item-title class="mr-menu">{{
                $t("Cuisines")
              }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item
            link
            to="/admin/tool"
            v-if="
              user && user.permissions && user.permissions.includes('get_tool')
            "
          >
            <v-list-item-content>
              <v-list-item-title class="mr-menu">{{
                $t("Tools")
              }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item
            link
            to="/admin/unit"
            v-if="
              user && user.permissions && user.permissions.includes('get_unit')
            "
          >
            <v-list-item-content>
              <v-list-item-title class="mr-menu">{{
                $t("Units")
              }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item
            link
            to="/admin/country"
            v-if="
              user &&
              user.permissions &&
              user.permissions.includes('get_country')
            "
          >
            <v-list-item-content>
              <v-list-item-title class="mr-menu">{{
                $t("Countries")
              }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item
            link
            to="/admin/city"
            v-if="
              user && user.permissions && user.permissions.includes('get_city')
            "
          >
            <v-list-item-content>
              <v-list-item-title class="mr-menu">{{
                $t("Cities")
              }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item
            link
            to="/admin/branch"
            v-if="
              user &&
              user.permissions &&
              user.permissions.includes('get_branch')
            "
          >
            <v-list-item-content>
              <v-list-item-title class="mr-menu">{{
                $t("Branches")
              }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item
            link
            to="/admin/period"
            v-if="
              user &&
              user.permissions &&
              user.permissions.includes('get_period')
            "
          >
            <v-list-item-content>
              <v-list-item-title class="mr-menu">{{
                $t("Periods")
              }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item
            link
            to="/admin/bank_name"
            v-if="
              user &&
              user.permissions &&
              user.permissions.includes('get_bank_name')
            "
          >
            <v-list-item-content>
              <v-list-item-title class="mr-menu">{{
                $t("BankNames")
              }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item
            link
            to="/admin/company"
            v-if="
              user &&
              user.permissions &&
              user.permissions.includes('get_company')
            "
          >
            <v-list-item-content>
              <v-list-item-title class="mr-menu">{{
                $t("Companies")
              }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item
            link
            to="/admin/group_name"
            v-if="
              user &&
              user.permissions &&
              user.permissions.includes('get_group_name')
            "
          >
            <v-list-item-content>
              <v-list-item-title class="mr-menu">{{
                $t("GroupNames")
              }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item
            link
            to="/admin/package"
            v-if="
              user &&
              user.permissions &&
              user.permissions.includes('get_package')
            "
          >
            <v-list-item-content>
              <v-list-item-title class="mr-menu">{{
                $t("Packages")
              }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          
            <v-list-item
            link
            to="/admin/packaging_group"
           
          >
            <v-list-item-content>
              <v-list-item-title class="mr-menu">{{
                $t("PackagingGroups")
              }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

            <v-list-item
            link
            to="/admin/packaging_group_city_period"
           
          >
            <v-list-item-content>
              <v-list-item-title class="mr-menu">{{
                $t("PackagingGroupCityPeriods")
              }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>


            <v-list-item
            link
            to="/admin/complaint_division"
           
          >
            <v-list-item-content>
              <v-list-item-title class="mr-menu">{{
                $t("ComplaintDivisions")
              }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>


        <v-list-item
            link
            to="/admin/complaint_type"
           
          >
            <v-list-item-content>
              <v-list-item-title class="mr-menu">{{
                $t("ComplaintTypes")
              }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>


        </v-list-group>

        <v-list-group
          v-if="
            (user &&
              user.permissions &&
              user.permissions.includes('get_ingredient')) ||
            user.permissions.includes('get_recipie') ||
            user.permissions.includes('get_menu')
          "
        >
          <template v-slot:activator>
            <v-list-item-icon>
              <v-icon>mdi-call-split</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{ $t("Resturant") }}</v-list-item-title>
            </v-list-item-content>
          </template>

          <v-list-item
            link
            to="/admin/ingredient"
            v-if="
              user &&
              user.permissions &&
              user.permissions.includes('get_ingredient')
            "
          >
            <v-list-item-content>
              <v-list-item-title class="mr-menu">{{
                $t("IngredientsMenu")
              }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item
            link
            to="/admin/recipie"
            v-if="
              user &&
              user.permissions &&
              user.permissions.includes('get_recipie')
            "
          >
            <v-list-item-content>
              <v-list-item-title class="mr-menu">{{
                $t("Recipies")
              }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item
            link
            to="/admin/menu"
            v-if="
              user && user.permissions && user.permissions.includes('get_menu')
            "
          >
            <v-list-item-content>
              <v-list-item-title class="mr-menu">{{
                $t("CusineMenu")
              }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item
            link
            to="/admin/menu2"
            v-if="
              user && user.permissions && user.permissions.includes('get_menu')
            "
          >
            <v-list-item-content>
              <v-list-item-title class="mr-menu">{{
                $t("CusineMenu2")
              }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

        </v-list-group>

        <v-list-group
          class="noBackground"
           v-if="
            (user &&
              user.permissions &&
              user.permissions.includes('get_subscribe')) ||
            user.permissions.includes('cancel_subscribe')
          "
        >
          <template v-slot:activator>
            <v-list-item-icon>
              <v-icon>mdi-arrow-up-bold-box-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{ $t("Sales") }}</v-list-item-title>
            </v-list-item-content>
          </template>

          <v-list-item
           
            link
            to="/admin/subscribe?type=&customer_name=&customer_mobile=&city_id=&branch_id="
            v-if="
              user &&
              user.permissions &&
              user.permissions.includes('get_subscribe')
            "
          >
            <v-list-item-content>
              <v-list-item-title class="mr-menu">{{
                $t("Subscriptions")
              }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>


           <v-list-item
            link
            
            to="/admin/subscribe?type=active&customer_name=&customer_mobile=&city_id=&branch_id="
            v-if="
              user &&
              user.permissions &&
              user.permissions.includes('get_subscribe')
            "
          >
            <v-list-item-content>
              <v-list-item-title class="mr-menu">{{
                $t("ActiveSubscriptions")
              }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

            <v-list-item
            link
            
            to="/admin/subscribe?type=stop&customer_name=&customer_mobile=&city_id=&branch_id="
            v-if="
              user &&
              user.permissions &&
              user.permissions.includes('get_subscribe')
            "
          >
            <v-list-item-content>
              <v-list-item-title class="mr-menu">{{
                $t("StopSubscriptions")
              }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

            <v-list-item
            link
            
            to="/admin/subscribe?type=expired&customer_name=&customer_mobile=&city_id=&branch_id="
            v-if="
              user &&
              user.permissions &&
              user.permissions.includes('get_subscribe')
            "
          >
            <v-list-item-content>
              <v-list-item-title class="mr-menu">{{
                $t("ExpiredSubscriptions")
              }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

            <v-list-item
            link
            
            to="/admin/subscribe?type=removed&customer_name=&customer_mobile=&city_id=&branch_id="
            v-if="
              user &&
              user.permissions &&
              user.permissions.includes('cancel_subscribe')
            "
          >
            <v-list-item-content>
              <v-list-item-title class="mr-menu">{{
                $t("RemovedSubscriptions")
              }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>




          <!-- 
            <v-list-item
            link
            to="/admin/resumeSubscribe"
          >
            <v-list-item-content>
              <v-list-item-title class="mr-menu">{{
                $t("ResumeSubscriptions")
              }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>              -->
        </v-list-group>


        <v-list-group
          v-if="

           user.permissions &&
            user.permissions.includes('get_admin') ||
            user.permissions.includes('get_permission') ||
            user.permissions.includes('get_activity')
          "
        >
          <template v-slot:activator>
            <v-list-item-icon>
              <v-icon>mdi-group</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{ $t("WorkGroup") }}</v-list-item-title>
            </v-list-item-content>
          </template>

           <v-list-item
            link
            to="/admin/customers"
            v-if="
               user &&
            user.permissions &&
            user.permissions.includes('get_activity')
            "
          >
            <v-list-item-content>
              <v-list-item-title class="mr-menu">{{
                $t("Customers")
              }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>


           <v-list-item
            link
            to="/admin/drivers"
            v-if="
               user &&
            user.permissions &&
            user.permissions.includes('get_activity')
            "
          >
            <v-list-item-content>
              <v-list-item-title class="mr-menu">{{
                $t("Drivers")
              }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>



          <v-list-item
            link
            to="/admin/user"
            v-if="
            user && user.permissions && user.permissions.includes('get_admin')
            "
          >
            <v-list-item-content>
              <v-list-item-title class="mr-menu">{{
                $t("Users")
              }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

            <v-list-item
            link
            to="/admin/user_permission"
            v-if="
            user && user.permissions && user.permissions.includes('get_permission')
            "
          >
            <v-list-item-content>
              <v-list-item-title class="mr-menu">{{
                $t("UsersPermissions")
              }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>


            <v-list-item
            link
            to="/admin/activities"
            v-if="
               user &&
            user.permissions &&
            user.permissions.includes('get_activity')
            "
          >
            <v-list-item-content>
              <v-list-item-title class="mr-menu">{{
                $t("WorkGroupActivities")
              }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item
            link
            to="/admin/failed-credit-cards"
          >
            <v-list-item-content>
              <v-list-item-title class="mr-menu">{{
                $t("FailedCreditCards")
              }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>



        </v-list-group>

        <v-list-item
          link
          to="/admin/areasCustomers"
        >
          <v-list-item-icon>
            <v-icon>mdi-dialpad</v-icon>
          </v-list-item-icon>
          <v-list-item-title>{{ $t("AreasCustomers") }}</v-list-item-title>
        </v-list-item>

        <v-list-item
          v-if="
            (user &&
              user.permissions &&
              user.permissions.includes('export_kitchen_today')) ||
            user.permissions.includes('export_quantities_today') ||
            user.permissions.includes('export_subscription_data') ||
            user.permissions.includes('export_renwal_data') ||
            user.permissions.includes('export_quantities_today')
          "
          link
          to="/admin/reportPage"
        >
          <v-list-item-icon>
            <v-icon>mdi-dialpad</v-icon>
          </v-list-item-icon>
          <v-list-item-title>{{ $t("Reports") }}</v-list-item-title>
        </v-list-item>

           <v-list-item

           v-if="
            (user &&
              user.permissions &&
              user.permissions.includes('verified_data'))
          "

          link
          to="/admin/approveReports"
        >
          <v-list-item-icon>
            <v-icon>mdi-dialpad</v-icon>
          </v-list-item-icon>
          <v-list-item-title>{{ $t("ApproveReports") }}</v-list-item-title>
        </v-list-item>


        <v-list-item
          v-if="
            (user &&
              user.permissions &&
              user.permissions.includes('import_customer')) ||
            user.permissions.includes('import_subscribe')
          "
          link
          to="/admin/importSubscribes"
        >
          <v-list-item-icon>
            <v-icon>mdi-dialpad</v-icon>
          </v-list-item-icon>
          <v-list-item-title>{{ $t("ImportSubscribes") }}</v-list-item-title>
        </v-list-item>

        <v-list-item
          v-if="
            (user &&
              user.permissions &&
              user.permissions.includes('import_customer')) ||
            user.permissions.includes('import_subscribe')
          "
          link
          to="/admin/transaction"
        >
          <v-list-item-icon>
            <v-icon>mdi-transfer</v-icon>
          </v-list-item-icon>
          <v-list-item-title>{{ $t("المدفوعات") }}</v-list-item-title>
        </v-list-item>

        <!-- <v-list-group v-if="user && user.roles && user.roles.includes('reports')">
          <template v-slot:activator>
            <v-list-item-icon>
              <v-icon>mdi-dialpad</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{ $t("Reports") }}</v-list-item-title>
            </v-list-item-content>
          </template>

          <v-list-item
            @click="goTo('/reports/cookingToday')"
          >
            <v-list-item-content>
              <v-list-item-title class="mr-menu">{{
                $t("CookingToday")
              }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>   

              <v-list-item
            @click="goTo('/reports/delegateToday')"
          >
            <v-list-item-content>
              <v-list-item-title class="mr-menu">{{
                $t("DelegateToday")
              }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item> 

              <v-list-item
            @click="goTo('/reports/quantitiesToday')"
          >
            <v-list-item-content>
              <v-list-item-title class="mr-menu">{{
                $t("QuantitiesToday")
              }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>           

        </v-list-group> -->

      
      </v-list>
    </v-navigation-drawer>

    <v-app-bar
      app
      class="bg-secondary"
      :style="lang == 'ar' ? 'direction:rtl;' : 'direction:ltr;'"
    >
      <v-row>
        <v-col md="12">
          <div>
            <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>
            <div
              class="d-flex align-center"
              :class="lang == 'ar' ? 'float-left ml-3' : 'float-right mr-3'"
            >
              <v-menu top left dense>
                <template v-slot:activator="{ on, attrs }">
                  <img
                    style="cursor: pointer"
                    @click="changeLang('ar')"
                    width="40"
                    height="40"
                    v-if="lang == 'en'"
                    src="/assets/ar.png"
                    alt=""
                  />
                  <img
                    style="cursor: pointer"
                    @click="changeLang('en')"
                    width="40"
                    height="40"
                    v-else
                    src="/assets/en.png"
                    alt=""
                  />

                  <p v-bind="attrs" v-on="on" class="mx-3 mt-4 clr-primary">
                    {{ user.admin.name }}
                  </p>

                  <!-- <v-img
                    v-bind="attrs"
                    v-on="on"
                    class="clickable"
                    :src="user.image"
                    style="
                      width: 50px;
                      height: 50px;
                      border-radius: 50%;
                      margin-left: auto;
                      margin-right: auto;
                      vertical-align: middle;
                    "
                  /> -->
                </template>
                <v-list dense>
                  <!-- <v-list-item dense>
                    <v-btn small color="success" text
                      >{{ $t("Profile") }}
                      <v-icon class="my-0 mr-4" dense>mdi-account</v-icon>
                    </v-btn>
                  </v-list-item> -->
                  <v-list-item dense>
                    <v-btn small color="danger" @click="logout" text
                      >{{ $t("Logout") }}

                      <v-icon class="my-0 mr-7" dense>mdi-logout</v-icon>
                    </v-btn>
                  </v-list-item>
                </v-list>
              </v-menu>
            </div>
          </div>
        </v-col>
      </v-row>
    </v-app-bar>

    <v-main>
      <v-container fluid>
        <router-view></router-view>
      </v-container>
    </v-main>

    <v-footer app> </v-footer>
  </v-app>
</template>

<script>
import config from "@/config";
import mixin from '@/mixin';
export default {
  name: "App",
  data: () => ({
    lang: localStorage.getItem("lang") || "ar",
    drawer: true,
    config: config,
    user: localStorage.getItem("user")
      ? JSON.parse(localStorage.getItem("user"))
      : [],
  }),
  methods: {
    changeLang(lang) {
      localStorage.setItem("lang", lang);
      window.location.reload();
    },
    goTo(page) {
      window.open(page);
    },
    logout() {
      this.$store
        .dispatch("auth/logout", this.object)
        .then((result) => {
          this.notifySuccess("Logout", result);
          this.logoutSuccess();
        })
        .catch((err) => {
          this.logoutSuccess();
        });
    },
  },
  mounted() {
    try {
       let user = localStorage.getItem("user")
      ? JSON.parse(localStorage.getItem("user"))
      : []
    if(!user ||  !user.admin || user.admin.permissions.length == 0) {
      // mixin.methods.logoutSuccess();
    } 
    } catch (error) {
      //  mixin.methods.logoutSuccess();
    }
  }
};
</script>
